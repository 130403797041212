import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import LoginInputs from "./LoginInputs";

const LoginFrom = () => {
  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item md="4" margin="auto">
            <Box>
              <Box>
                <Typography
                  variant="h4"
                  color="#212B36"
                  fontWeight="bold"
                  gutterBottom
                  component="h4"
                >
                  Sign in to SolSniper
                </Typography>
                <Box>
                  <Typography
                    variant="body"
                    color="#212B36"
                    fontWeight="400"
                    gutterBottom
                    component="body"
                  >
                    Don’t have an account? &nbsp;
                    <Link to="/">Get started</Link>
                  </Typography>
                </Box>
              </Box>
              <Box paddingY='3rem'>
                <LoginInputs />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default LoginFrom;
