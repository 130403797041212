import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from 'swiper';
import { Container } from '@mui/system';
import { Label } from '@mui/icons-material';
import { Divider, TextField } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Box className='buy_now'>
                <Button onClick={handleOpen}>Buy Now</Button>
            </Box>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                <Box sx={style} className='Checkout'>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Checkout
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        You are about to purchase a Philipp Potocnik #655 from Jonathan Helen
                    </Typography>

                    <Box className='quantity' paddingTop={'10px'}>
                        <Typography variant='h5' fontSize={'18px'}>
                            Enter quantity <span>(20 available)</span>
                        </Typography>
                        <br></br>
                        <TextField id="outlined-basic" fullWidth variant="outlined" />
                    </Box>

                    <Box className='decimal' marginY='1em' padding='3px 10px'  sx={{borderRadius:'4px', background:'#7022856b'}}>
                        <Box className='serviced' justifyContent='space-between' display='flex' alignItems='center' marginY='10px'>
                            <Typography variant='h3' fontSize='18px' fontWeight='400'>
                            Balance
                            </Typography>
                            <Typography variant='h6' fontSize='18px' fontWeight='normal'>
                            8.23732 ETH
                            </Typography>
                        </Box>
                        <Divider />
                        <Box className='serviced' justifyContent='space-between' display='flex' alignItems='center' marginY='10px'>
                            <Typography variant='h3' fontSize='18px' fontWeight='400'>
                            Service fee 2.5%
                            </Typography>
                            <Typography variant='h6' fontSize='18px' fontWeight='normal'>
                            0.00125 ETH
                            </Typography>
                        </Box>
                        <Divider />
                        <Box className='serviced' justifyContent='space-between' display='flex' alignItems='center' marginY='10px'>
                            <Typography variant='h3' fontSize='18px' fontWeight='400'>
                            You will pay
                            </Typography>
                            <Typography variant='h6' fontSize='18px' fontWeight='normal'>
                            0.010691 ETH
                            </Typography>
                        </Box>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}
