import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import styled from "styled-components";

const Unlist = styled.ul`
  display: flex;
  align-items: center;
  margin: 0px;
  gap: 15px;
  list-style: none;
  padding-left: 0px;
  font-size: 14px;
`;
const Lists = styled.li`

  letter-spacing: 1px;
`;

const CopyRightFooter = () => {
  return (
    <div>
      <Box bgcolor="#242636" padding="1rem" marginTop={'2rem'}>
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid item lg="4" md="4" sm="12" xs="12">
              <Box>
                <Unlist>
                  <Lists>
                    <Link to="#" style={{ color: "#CCD5DF", textDecoration: "none" }} >
                      Terms and Conditions
                    </Link>
                  </Lists>
                  <Lists>
                    <Link to="#" style={{ color: "#CCD5DF", textDecoration: "none" }}>
                     Privacy Policy
                    </Link>
                  </Lists> 
                </Unlist>
              </Box>
            </Grid>
            <Grid item lg="4" md="4" sm="12" xs="12">
              <Box>
                <Box  display="flex"   alignItems="center" justifyContent="center" gap="20px" >
                  <Box>
                    <Link to="/">
                      <InstagramIcon sx={{ color: "#CCD5DF" }} />
                    </Link>
                  </Box>
                  <Box>
                    <Link to="/">
                      <TwitterIcon sx={{ color: "#CCD5DF" }} />
                    </Link>
                  </Box>
                  <Box>
                    <Link to="/">
                      <FacebookIcon sx={{ color: "#CCD5DF" }} />
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg="4" md="4" sm="12" xs="12">
              <Box textAlign="right">
                <Box
                  display="flex"
                  alignItems="center"
                  gap="18px"
                  sx={{ justifyContent: { md: "right", xs: "center" } }}
                >
                  <Typography variant="body1" color="#CCD5DF">
                    <Link
                      to="/"
                      style={{ color: "#CCD5DF", textDecoration: "none" }}
                    >
                      &copy; 2022 Sniper Labs, Inc. All Rights Reserved.
                    </Link>
                  </Typography>
                  {/* <Typography variant="body1" color="#CCD5DF">
                    <Link
                      to="/"
                      style={{ color: "#CCD5DF", textDecoration: "none" }}
                    >
                      By Thinker Technology
                    </Link>
                  </Typography> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default CopyRightFooter;
