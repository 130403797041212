import React from 'react'
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import move from "../../Assets/Img/move.png";
import eth from "../../Assets/Img/eth.png";
import DecriptionModal from './DecriptionModal';

const Description = () => {
    return (
        <div>
            <Container maxWidth="xl" sx={{ position: "relative", marginY: '2rem' }}>
                <Typography variant='h2' fontSize='40px' fontWeight='600' paddingBottom={'20px'}> Description ✨ </Typography>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <Box className='Potocnik_pic'>
                            <img src={move} width="100%" alt="login images" />
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <Box className='Philip'>
                            <Typography className='punk' variant="h2">
                                Philipp Potocnik
                            </Typography>
                            <Typography variant="h6">
                                Category:-  <span>ART</span>
                            </Typography>
                            <Typography variant="h6">
                                Price:-  <span>100 Matic</span>
                            </Typography>
                            <Typography variant="h6">
                                Owner:-  <span>0xfa2f2f04EFe4D5F839405E3Bb</span>
                            </Typography>

                            <Box className='Patter' display={'flex'} marginTop={'20px'}>
                                <Box className='Patterfly'>
                                    <img src={move} width="30px" alt="login images" />
                                </Box>
                                <Box className='jonathan'>
                                    <Typography variant='h4'>
                                        Collection
                                    </Typography>
                                    <Typography variant='h6'>
                                        Patterfly Studio
                                    </Typography>
                                </Box>
                            </Box>
                            <br></br>
                            <hr></hr>
                            <Typography variant='h5' fontSize='25px' fontWeight='500'>
                                Price
                            </Typography>
                            <Box className='priced' display={'flex'} marginTop={'5px'}>
                                <Box className='Panter'>
                                    <img src={eth} alt="login images" />
                                </Box>
                                <Box className='jonathan'>
                                    <Typography variant='h4'>
                                        13.2595
                                    </Typography>
                                </Box>
                            </Box>
                            <hr></hr>

                            <Box> 
                                <DecriptionModal />
                            </Box>

                            <Box paddingY={'15px'} lineHeight='22px' fontWeight='300'>
                                <Typography variant='h5' fontSize='25px' fontWeight='500'>
                                    History
                                </Typography>
                                <Typography variant='' component={'div'}>
                                    The official lisque persius interesset his et, in quot quidam simply
                                    dummy text of the printing persequeris essent possim iriure. Lorem Ipsum
                                    is simply dummy text of the printing and typesetting industry.The official lisque persius interesset his et, in
                                    quot quidam simply dummy text of the printing
                                    persequeris essent possim iriure. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                </Typography>
                            </Box>

                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default Description;
