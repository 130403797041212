import { Box } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';


const Inputbase = styled.input`
    width:100%;
    background: #373943;
    border-radius: 10px;
    border: 1px solid #828282 !important;
    padding: 8px 8px 8px 45px;
    font-size: 18px;
    color: #828282;
    
`


const SearchBar = () => {
  return (
    <div>
        <Box position='relative'>
            <Box position='absolute' top='0' left='14px' bottom='0' display='flex' alignItems='center'>
                <SearchIcon sx={{ color:'#828282', cursor:'pointer' }}/> 
            </Box>
            <Inputbase type='search' id='search' placeholder='Search by Domain' />
        </Box>
    </div>
  )
}

export default SearchBar;