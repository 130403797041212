import { Box, Container, Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import Slider from "react-slick";
import vandal from "../../Assets/Img/vandal_city_sm.webp";
import droid from "../../Assets/Img/droid_capital_sm.jpg";
import Bear from "../../Assets/Img/okay_bears_sm.webp";
import thenxde from "../../Assets/Img/thenxde_sm.webp";
import citizen from "../../Assets/Img/citizens_by_solsteads_sm.webp";
import atrivians from"../../Assets/Img/atrivians_sm.webp";
import toos from "../../Assets/Img/t00bs_sm.webp";
import zenjinviperz from "../../Assets/Img/zenjinviperz_sm.jpg";
export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
            arrows:false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            speed: 1000,
          },
        },
      ],
    };
    return (
      <div className="">
        <Container maxWidth="xl" sx={{ position: "relative" }}>
          <h2>Popular 🔥 </h2>
          <Slider {...settings}>
            <div>
              <Box className="Popular-nft">
              
                <Box className="Popular_one">
                  <img src={vandal} width="15%" alt="vandal images" />
                  <Box className="vandal">
                    <Typography variant="div" component={"div"}>
                      
                      Vandals
                    </Typography>
                    <Typography className="citzen" variant="div" component={"div"}>
                     
                      vandal_city
                    </Typography>
                  </Box>
                </Box>

                <Box className="flr" display={'flex'} justifyContent={'space-between'}>
                <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $6.19
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Floor
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $36.19
                  </Typography>
                  <Typography  className="flr-sizes" variant="div" component={"div"}>
                    Volume
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography  className="flr-size" variant="div" component={"div"}>
                    309
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Listing
                  </Typography>
                  </Box>
                </Box>

              </Box>
            </div>

            <div>
          
            <Box className="Popular-nfts" style={{background:'#351428'}}>
                <Box className="Popular_one">
                  <img src={droid} width="15%" alt="droid images" />
                  <Box className="vandal">
                    <Typography variant="div" component={"div"}>
                      
                    Droid Capital
                    </Typography>
                    <Typography className="citzen" variant="div" component={"div"}>
                     
                    droid_capital
                    </Typography>
                  </Box>
                </Box>

                <Box className="flr" display={'flex'} justifyContent={'space-between'}>
                <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $26.19
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Floor
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $116.19
                  </Typography>
                  <Typography  className="flr-sizes" variant="div" component={"div"}>
                    Volume
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography  className="flr-size" variant="div" component={"div"}>
                   604
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Listing
                  </Typography>
                  </Box>
                </Box>
</Box>
             
            </div>
            <div>
            <Box className="Popular-nft" style={{background:'#1a413f'}}>
                <Box className="Popular_one">
                  <img src={Bear} width="15%" alt="vandal images" />
                  <Box className="vandal">
                    <Typography variant="div" component={"div"}>
                      
                    Okay Bears
                    </Typography>
                    <Typography className="citzen" variant="div" component={"div"}>
                     
                    okay_bears
                    </Typography>
                  </Box>
                </Box>

                <Box className="flr" display={'flex'} justifyContent={'space-between'}>
                <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $203.19
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Floor
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $11.19
                  </Typography>
                  <Typography  className="flr-sizes" variant="div" component={"div"}>
                    Volume
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography  className="flr-size" variant="div" component={"div"}>
                   1123
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Listing
                  </Typography>
                  </Box>
                </Box>

              </Box>
            </div>
            <div>
            <Box className="Popular-nft" style={{background:'#342149'}}>
                <Box className="Popular_one">
                  <img src={thenxde} width="15%" alt="vandal images" />
                  <Box className="vandal">
                    <Typography variant="div" component={"div"}>
                      
                     Thenxde
                    </Typography>
                    <Typography className="citzen" variant="div" component={"div"}>
                      
                      thenxde
                     </Typography>
                  </Box>
                </Box>

                <Box className="flr" display={'flex'} justifyContent={'space-between'}>
                <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $58.19
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Floor
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $607.19
                  </Typography>
                  <Typography  className="flr-sizes" variant="div" component={"div"}>
                    Volume
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography  className="flr-size" variant="div" component={"div"}>
                   445
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Listing
                  </Typography>
                  </Box>
                </Box>

              </Box>
            </div>
            <div>
            <Box className="Popular-nft" style={{background:'#20383b'}}>
                <Box className="Popular_one">
                  <img src={citizen} width="15%" alt="vandal images" />
                  <Box className="vandal">
                    <Typography variant="div" component={"div"}>
                      Citizens By Solsteads
                      
                    </Typography>
                    <Typography className="citzen" variant="div" component={"div"}>
                     
                     citizens_by_solsteads
                    </Typography>
                  </Box>
                </Box>

                <Box className="flr" display={'flex'} justifyContent={'space-between'}>
                <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $6.19
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Floor
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $36.19
                  </Typography>
                  <Typography  className="flr-sizes" variant="div" component={"div"}>
                    Volume
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography  className="flr-size" variant="div" component={"div"}>
                    309
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Listing
                  </Typography>
                  </Box>
                </Box>

              </Box>
            </div>
            <div>
            <Box className="Popular-nft" style={{background:'#4a1236'}}>
                <Box className="Popular_one">
                  <img src={atrivians} width="15%" alt="vandal images" />
                  <Box className="vandal">
                    <Typography variant="div" component={"div"}>
                      
                    Atrivians
                    </Typography>
                    <Typography className="citzen" variant="div" component={"div"}>
                     
                    Atrivians
                    </Typography>
                  </Box>
                </Box>

                <Box className="flr" display={'flex'} justifyContent={'space-between'}>
                <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $76.19
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Floor
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $456.19
                  </Typography>
                  <Typography  className="flr-sizes" variant="div" component={"div"}>
                    Volume
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography  className="flr-size" variant="div" component={"div"}>
                   112
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Listing
                  </Typography>
                  </Box>
                </Box>

              </Box>
            </div>
            <div>
            <Box className="Popular-nft" style={{background:'#31495d'}}>
                <Box className="Popular_one">
                  <img src={toos} width="15%" alt="vandal images" />
                  <Box className="vandal">
                    <Typography variant="div" component={"div"}>
                      
                    Y00bs
                    </Typography>
                    <Typography className="citzen" variant="div" component={"div"}>
                     
                  y00bs
                    </Typography>
                  </Box>
                </Box>

                <Box className="flr" display={'flex'} justifyContent={'space-between'}>
                <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $76.19
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Floor
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $456.19
                  </Typography>
                  <Typography  className="flr-sizes" variant="div" component={"div"}>
                    Volume
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography  className="flr-size" variant="div" component={"div"}>
                   112
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Listing
                  </Typography>
                  </Box>
                </Box>

              </Box>
            </div>
            <div>
            <Box className="Popular-nft" style={{background:'#4b3b4c'}}>
                <Box className="Popular_one">
                  <img src={zenjinviperz} width="15%" alt="vandal images" />
                  <Box className="vandal">
                    <Typography variant="div" component={"div"}>
                      
                   Zenjin Viperz
                    </Typography>
                    <Typography className="citzen" variant="div" component={"div"}>
                     
                   zenjinviperz
                    </Typography>
                  </Box>
                </Box>

                <Box className="flr" display={'flex'} justifyContent={'space-between'}>
                <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $76.19
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Floor
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography className="flr-size" variant="div" component={"div"}>
                    $456.19
                  </Typography>
                  <Typography  className="flr-sizes" variant="div" component={"div"}>
                    Volume
                  </Typography>
                  </Box>
                  <Box className="flr_one">
                  <Typography  className="flr-size" variant="div" component={"div"}>
                   112
                  </Typography>
                  <Typography className="flr-sizes" variant="div" component={"div"}>
                    Listing
                  </Typography>
                  </Box>
                </Box>

              </Box>
            </div>
          </Slider>
        </Container>
      </div>
    );
  }
}
