import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Modal, Button, Text} from "@nextui-org/react";

const ConnectBTN = styled.button`
  padding: 13px 40px;
  border-radius: 50px;
  border: none;
  background: #bb27e2;
  color: #ffffff;
  font-size: 17px;
  letter-spacing: 1px;
  cursor: pointer;
`;

const ConnectWallet = () => {
  const [visible, setVisible] = React.useState(false);
  const handler = () => setVisible(true);

  const closeHandler = () => {
    setVisible(false);
    console.log("closed");
  };

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <ConnectBTN type="button" auto shadow onClick={handler}>
          Connect
        </ConnectBTN>
        <Modal
          closeButton
          aria-labelledby="modal-title"
          open={visible}
          onClose={closeHandler}
          style={{backgroundColor:'#000'}}
        >
          <Modal.Header>
            <Text id="modal-title" size={18} color='#fff'>
              Welcome to
              <Text b size={18}  color='#38f2af'>
               &nbsp; Wallet Connect
              </Text>
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Box paddingBottom='1.5rem'>
              <Button flat color="success" style={{width:'100%'}}>
                Metamask Wallet
              </Button>
              <Button flat color="success" style={{width:'100%', marginTop:'1rem'}}>
                Wallet Connect
              </Button>
            </Box>
          </Modal.Body> 
        </Modal>
      </Box>
    </div>
  );
};

export default ConnectWallet;
