import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import crasers from "../../Assets/Img/wallet.png";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal2({ open, handleOpen, handleClose }) {

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  >
        <Box sx={style} className='Solana'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            You'll need a wallet on Solana to continue
          </Typography>
          <Box className='img_wall'>
            <img className='wallet_img' src={crasers} width="100%" alt="crasers images" />
          </Box>
          <Button variant='contained'>Get Started</Button>
        </Box>
      </Modal>
    </div>
  );
}
