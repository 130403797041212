import { Box, Container, Grid, Typography, Button } from "@mui/material";
import React from 'react'
import { Link } from "react-router-dom";
import img from "../../Assets/Img/solami.jpg";
import solrunner from "../../Assets/Img/solrunner.jpg";

const Browse = () => {
  return (
    <div>
      <Container maxWidth="xl" sx={{ position: "relative", marginY: '2rem' }} >
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="browse-nft">
              <Typography variant="h2" component={"h2"} fontSize='45px' fontWeight='600'>
                Explore
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Box className="browse-bck">
              <Box className="browse-nfts">
              <Link to={'/Description'}>  <img src={img} width="100%" /> </Link>
                <Box className="proud">
                  <Box className="proud-name">
                    <Typography className="art1" variant="h6">
                      y00ts: mint t00b #14429
                    </Typography>
                  </Box>
                  <Typography className="card-name">
                    <Typography className="card-price" variant="div" component={"div"}>
                      $5.85K
                    </Typography>
                    <Button className="Buy" variant="contained" color="success">
                      Buy Now
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Box className="browse-bck">
              <Box className="browse-nfts">
              <Link to={'/Description'}>  <img src={solrunner} width="100%" /> </Link>
                <Box className="proud">
                  <Box className="proud-name">
                    <Typography className="art1" variant="h6">
                      y00ts: mint t00b #14429
                    </Typography>
                  </Box>
                  <Typography className="card-name">
                    <Typography className="card-price" variant="div" component={"div"}>
                      $5.85K
                    </Typography>
                    <Button className="Buy" variant="contained" color="success">
                      Buy Now
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Box className="browse-bck">
              <Box className="browse-nfts">
              <Link to={'/Description'}>  <img src={solrunner} width="100%" /> </Link>
                <Box className="proud">
                  <Box className="proud-name">
                    <Typography className="art1" variant="h6">
                      y00ts: mint t00b #14429
                    </Typography>
                  </Box>
                  <Typography className="card-name">
                    <Typography className="card-price" variant="div" component={"div"}>
                      $5.85K
                    </Typography>
                    <Button className="Buy" variant="contained" color="success">
                      Buy Now
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Box className="browse-bck">
              <Box className="browse-nfts">
              <Link to={'/Description'}>  <img src={img} width="100%" /> </Link>
                <Box className="proud">
                  <Box className="proud-name">
                    <Typography className="art1" variant="h6">
                      y00ts: mint t00b #14429
                    </Typography>
                  </Box>
                  <Typography className="card-name">
                    <Typography className="card-price" variant="div" component={"div"}>
                      $5.85K
                    </Typography>
                    <Button className="Buy" variant="contained" color="success">
                      Buy Now
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Box className="browse-bck">
              <Box className="browse-nfts">
              <Link to={'/Description'}>  <img src={solrunner} width="100%" /> </Link>
                <Box className="proud">
                  <Box className="proud-name">
                    <Typography className="art1" variant="h6">
                      y00ts: mint t00b #14429
                    </Typography>
                  </Box>
                  <Typography className="card-name">
                    <Typography className="card-price" variant="div" component={"div"}>
                      $5.85K
                    </Typography>
                    <Button className="Buy" variant="contained" color="success">
                      Buy Now
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Box className="browse-bck">
              <Box className="browse-nfts">
              <Link to={'/Description'}>  <img src={img} width="100%" /> </Link>
                <Box className="proud">
                  <Box className="proud-name">
                    <Typography className="art1" variant="h6">
                      y00ts: mint t00b #14429
                    </Typography>
                  </Box>
                  <Typography className="card-name">
                    <Typography className="card-price" variant="div" component={"div"}>
                      $5.85K
                    </Typography>
                    <Button className="Buy" variant="contained" color="success">
                      Buy Now
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={12}>
            <Box className="browse-bck">
              <Box className="browse-nfts">
              <Link to={'/Description'}>  <img src={solrunner} width="100%" /> </Link>
                <Box className="proud">
                  <Box className="proud-name">
                    <Typography className="art1" variant="h6">
                      y00ts: mint t00b #14429
                    </Typography>
                  </Box>
                  <Typography className="card-name">
                    <Typography className="card-price" variant="div" component={"div"}>
                      $5.85K
                    </Typography>
                    <Button className="Buy" variant="contained" color="success">
                      Buy Now
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Browse
