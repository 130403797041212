import { Box, Container, Grid, Typography, Button } from "@mui/material";
import React, { Component } from "react";
import Slider from "react-slick";
import crasers from "../../Assets/Img/crasers.png";
import just from "../../Assets/Img/just-ape.png";
import metacreed from "../../Assets/Img/metacreed.gif";
import stoned from "../../Assets/Img/stoned-hippies.png";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      arrows:false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            autoplay:true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,
          },
        },
      ],
    };
    return (
      <div className="Collabo_start">
        <Container maxWidth="xl" sx={{ position: "relative" }}>
          <h2>Collaborations 🤝 </h2>
          <Slider {...settings}>
            <div>
              <Box className="collab-nft" style={{ background: '#3a3a46' }} display="flex">

                <Box className="collab_one">
                  <img src={crasers} width="100%" alt="crasers images" />

                </Box>
                <Box className="collab_two">
                  <Typography className="ape" variant="h5" component={"h5"}>
                    Crasers
                  </Typography>
                  <Typography className="community" variant="div" component={"div"}>
                    Built by ex-EA game talent and DeFi veterans to combine AAA gaming & GameFi.
                    Launching NFTs, DEX, staking, and in-game token $CREED.
                  </Typography>
                  <Button className="view" variant="contained" color="success">
                    View Tiwtter
                  </Button>
                </Box>

              </Box>
            </div>
            <div>
              <Box className="collab-nft" style={{ background: '#151b2e' }} display="flex">

                <Box className="collab_one">
                  <img src={just} width="100%" alt="just-ape images" />

                </Box>
                <Box className="collab_two">
                  <Typography className="ape" variant="h5" component={"h5"}>
                    Just Ape. ✨
                  </Typography>
                  <Typography className="community" variant="div" component={"div"}>
                    A collection of Apes that takes us back to the basics. This project is bridging the gap between Web2 and Web3
                    while building a vibrant community.
                    Ape Adventure Pass commenced in July — send your Apes on an adventure to earn rewards!
                  </Typography>
                  <Button className="view" variant="contained" color="success">
                    View Tiwtter
                  </Button>
                </Box>
              </Box>
            </div>
            <div>
              <Box className="collab-nft" style={{ background: '#301e39' }} display="flex">

                <Box className="collab_one">
                  <img src={metacreed} width="100%" alt="crasers images" />

                </Box>
                <Box className="collab_two">
                  <Typography className="ape" variant="h5" component={"h5"}>
                    Metacreed
                  </Typography>
                  <Typography className="community" variant="div" component={"div"}>
                    Built by ex-EA game talent and DeFi veterans to combine AAA gaming & GameFi.
                    Launching NFTs, DEX, staking, and in-game token $CREED.
                  </Typography>
                  <Button className="view" variant="contained" color="success">
                    View Tiwtter
                  </Button>
                </Box>
              </Box>
            </div>
            <div>
              <Box className="collab-nft" style={{ background: '#373534' }} display="flex">

                <Box className="collab_one">
                  <img src={stoned} width="100%" alt="crasers images" />

                </Box>
                <Box className="collab_two">
                  <Typography className="ape" variant="h5" component={"h5"}>
                    Stoned Hippies Realm ✌️
                  </Typography>
                  <Typography className="community" variant="div" component={"div"}>
                    Peace, Puff, and Positivity. Connecting those who love travel, music, and the legacy of hippiedom,
                    this NFT community of stoned hippies is coming soon on Solana.
                  </Typography>
                  <Button className="view" variant="contained" color="success">
                    View Tiwtter
                  </Button>
                </Box>

              </Box>
            </div>





          </Slider>
        </Container>
      </div>

    );
  }
}
