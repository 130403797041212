import * as React from 'react';
import { Table } from '@mui/material';
import crasers from "../../Assets/Img/crasers.png";
import grap from "../../Assets/Img/grap.png";
import { Link } from 'react-router-dom';

function Trandingone() {
    return (
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th> Name</th>
                    <th>Floor</th>
                    <th>10 Min Floor</th>
                    <th>7d Trend</th>
                    <th>10 Min Sale</th>
                    <th>10 Min Buyers</th>
                    <th>10 Min Volume</th>
                    <th>Volume</th>
                    <th>Listed / Supply </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>2</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>
                <tr>
                    <td>7</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td> <img className='pht_inner' src={crasers} width="50" alt="crasers images" />
                     <Link to={'Browse'}>   Classee Potion  </Link> 
                     </td>
                    <td>--</td>
                    <td>--</td>
                    <td>----</td>
                    <td>--</td>
                    <td><img className='' src={grap} width="50" alt="crasers images" /></td>
                    <td>--</td>
                    <td>1522</td>
                    <td>??</td>
                </tr>

            </tbody>
        </Table>

    );
}

export default Trandingone;