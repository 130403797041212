import React from 'react'
import Popular from '../Popular/Popular';
import Collobration from '../Collobration/collobration'
import User from '../User/User'
import Tranding from '../Tranding/Tranding';

const Landing = () => {
  return (
    <div> 
      <Popular />
      <Collobration />
      <Tranding />
      <User />
    </div>
  )
}

export default Landing;