import { Box } from "@mui/material";
import React from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import ConnectWallet from "./ConnectWallet";
import SearchBar from "./SearchBar";
import logo from "../../Assets/Img/apple-touch-icon.png";
import { Link } from "react-router-dom";

const pages = [
  {
    link: "",
    menuspage: "Home",
  },
  {
    link: "Browse",
    menuspage: "Watchlist",
  },
  {
    link: "/",
    menuspage: "Live",
  },
  {
    link: "/",
    menuspage: "Trade",
  },
  {
    link: "/",
    menuspage: "Portfolio",
  },
  {
    link: "/Browse",
    menuspage: "Explore ",
  },
];

const MenuHeader = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box>
      <AppBar position="static" sx={{ background: "#0c0a1e", boxShadow:' 0px 0px 10px #373943;' }} className='header_part' >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box alignItems="center" sx={{ display: { xs: "none", md: "flex" }, }}>
              <Link to="/">
                <img src={logo} width="80px" alt="shiba net logo" />
              </Link>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton size="large" aria-label="account of current user"
                aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit" >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem key={index} onClick={handleCloseNavMenu} sx={{background:'#fff',}}>
                    <Link
                      to={page.link}
                      style={{ textDecoration: "none", letterSpacing: "1px" }}
                    >
                      <Typography textAlign="center" color='#000'>
                        {page.menuspage}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              <Link to="/">
                <img src={logo} width="80px" alt="shiba net logo" />
              </Link>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { md: "block", xs: "none" },
              }}
            >
              <SearchBar />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                gap: "25px",
                alignItems: "center",
                justifyContent: { md: "right", xs: 'start' },
                marginX: "1rem",
              }}
            >
              {pages.map((page, index) => (
                <Link
                  key={index}
                  onClick={handleCloseNavMenu}
                  to={page.link}
                  style={{ textDecoration: "none", letterSpacing: "1px", color: '#fff' }}
                >
                  {page.menuspage}
                </Link>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <ConnectWallet />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default MenuHeader;
