import { Box, TextField } from '@mui/material'; 
import React from 'react'
import styled from 'styled-components';

const ForgotInputs = () => {
  return (
    <div>
       
       
        <Box marginY='2rem'>
            <TextField type='email' fullWidth label="Enter Email" id="fullWidth" /> 
        </Box>
        
        <Box>
            <Login>
                SIGN UP
            </Login>
        </Box>
    </div>
  )
}

export default ForgotInputs;

const Login = styled.button`
    padding: 8px 22px;
    border-radius: 6px;
    color: rgb(255, 255, 255);
    background-color: rgb(32, 101, 209);
    width: 100%;
    box-shadow: rgb(32 101 209 / 24%) 0px 8px 16px 0px;
    height: 48px;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    font-size:20px
`