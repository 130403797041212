import { Box, Container, Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import Slider from "react-slick";
import ragnar from "../../Assets/Img/ragnar.jpg";
import puggl3 from "../../Assets/Img/puggl3.png";
import solami from "../../Assets/Img/solami.jpg";
import solrunner from "../../Assets/Img/solrunner.jpg";
import grishuh from "../../Assets/Img/grishuh.png";
import jerzy from "../../Assets/Img/jerzy.png";
import smoothie from "../../Assets/Img/smoothie.jpg";
import bec from "../../Assets/Img/bec.png";
import jay from "../../Assets/Img/jay.jpg";
import scar from "../../Assets/Img/scar.png";
import cody from "../../Assets/Img/cody.png";
export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
            arrows:false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows:false,
          },
        },
      ],
    };
    return (
      <div className="">
        <Container maxWidth="xl" sx={{ position: "relative" }}>
          <h2> Featured Users ✨</h2>
          <Slider {...settings}>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                   <img src={cody} width="100%" alt="ragnar" />
                  <Typography className="ragnars" variant="div" component={"div"}>
                      
                  ItsRagnar
                    </Typography>
                  
                </Box>

               
              </Box>
            </div>
            <div>
              <Box className="user-nft"> 
                <Box className="user_one">
                  <img src={ragnar} width="100%" alt="ragnar" />
                  <Typography className="ragnars" variant="div" component={"div"}>
                      
                  ItsRagnar
                    </Typography>
                  
                </Box>

               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={puggl3} width="100%" alt="ragnar" />
                  <Typography className="ragnars" variant="div" component={"div"}>
                      
                  puggl3
                    </Typography>
                  
                </Box>

               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={solami} width="100%" alt="ragnar" />
                  <Typography className="ragnars" variant="div" component={"div"}>
                      
                  Solami Mommy
                    </Typography>
                  
                </Box>

               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={solrunner} width="100%" alt="ragnar" />
                  <Typography className="ragnars" variant="div" component={"div"}>
                      
                  S◎L Runner
                    </Typography>
                  
                </Box>

               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={grishuh} width="100%" alt="ragnar" />
                  
                </Box>
                <Typography className="ragnars" variant="div" component={"div"}>
                      
                Grishuh
                        </Typography>
               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={jerzy} width="100%" alt="ragnar" />
                  
                </Box>
                <Typography className="ragnars" variant="div" component={"div"}>
                      
                Jerzy
                              </Typography>
               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={smoothie} width="100%" alt="ragnar" />
                </Box>
                <Typography className="ragnars" variant="div" component={"div"}>
                      
                Smoothie
                                    </Typography>
               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={bec} width="100%" alt="ragnar" />
                  
                </Box>
                <Typography className="ragnars" variant="div" component={"div"}>
                      
                bec
                                          </Typography>
               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={jay} width="100%" alt="ragnar" />
                  
                </Box>
                <Typography className="ragnars" variant="div" component={"div"}>
                      
                SolTrappist
                                                </Typography>
                     
               
              </Box>
            </div>
            <div>
              <Box className="user-nft">
              
                <Box className="user_one">
                  <img src={scar} width="100%" alt="ragnar" />
                  
                </Box>
                <Typography className="ragnars" variant="div" component={"div"}>
                      
                Scarborough
                                                      </Typography>
               
              </Box>
            </div>

           
         
           
          
           
          </Slider>
        </Container>
      </div>
    );
  }
}
