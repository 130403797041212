import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/system';
import { Button, Menu, MenuItem } from '@mui/material';
import Trandingone from './Trandingone';
import Trandingsecond from './Trandingsecond';
import BasicModal from './Portfoliamodal';
import BasicModal2 from './Portfoliamodalsecond';
import Filterslider from './Filterslider'; 
import Searchsection from './Searchsection';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const [open, setOpen] = React.useState(false);
    const handleOpen = (name) => setOpen(name);
    const handleClose = () => setOpen(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (<>
        <Box className='Tabs_start' paddingY={5}>
            <Container maxWidth='xl'>
            <h2> Trending </h2>
            <p>Default ranked by change in sales volume </p>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='quality'>
                       
                            <Tab className='main_btn' label="Trending" {...a11yProps(0)} />
                            <Tab className='main_btn' label="Watchlist" {...a11yProps(1)} />
                            <Button variant='contained' onClick={() => handleOpen('Filter')}>Portfolio </Button>
                            <Button variant='contained' onClick={() => handleOpen('Portfolia')}>Customize </Button>
                           
                            <Button  variant='contained' aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}>Filter</Button>
                                   <Searchsection />
                            <Menu  
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                // PopoverClasses={{}}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                            >
                                <Box>
                                    <Filterslider />
                                    {/* <Typography>Something</Typography> */}
                                </Box>
                            </Menu>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {/* <Trandingone /> */}
                        <Trandingone />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Trandingsecond />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Button variant='contained'>Filter</Button>
                    </TabPanel>
                </Box>
            </Container>
        </Box>
        <BasicModal open={open === 'Portfolia'} handleOpen={handleOpen} handleClose={handleClose} />
        <BasicModal2 open={open === 'Filter'} handleOpen={handleOpen} handleClose={handleClose} />
    </>);
}
