import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ open, handleOpen, handleClose }) {

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  >
        <Box sx={style} className='Customize'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Customize Table
          </Typography>
          <hr></hr>
          <Typography variant='h5'>Columns</Typography>
          <Box className='' sx={{ background: '#1d212e', borderRadius: '5px', padding: '10px' }} >

            <Box className='floor-sec'>
              <Box className='finner_flor'>
                <Typography variant=''>
                  1
                </Typography>
                Floor
              </Box>

              <Box className='finner_flor'>
                <Typography variant=''>
                  2
                </Typography>
                Floor
              </Box>
              <Box className='finner_flor'>
                <Typography variant=''>
                  3
                </Typography>
                Floor
              </Box>
              <Box className='finner_flor'>
                <Typography variant=''>
                 4
                </Typography>
                Floor
              </Box>
              <Box className='finner_flor'>
                <Typography variant=''>
                 5
                </Typography>
                Floor
              </Box>
              <Box className='finner_flor'>
                <Typography variant=''>
                 6
                </Typography>
                Floor
              </Box>
              <Box className='finner_flor'>
                <Typography variant=''>
                7
                </Typography>
                Floor
              </Box>

            </Box>
          </Box>
          <br></br>
          <Typography variant='h5'>Select</Typography>
          <Box className='floor-sec'>
              <Box className='finner_flor'>
                <Typography variant=''>
                  1
                </Typography>
                Floor
              </Box>

              <Box className='finner_flor'>
                <Typography variant=''>
                  2
                </Typography>
                Floor
              </Box>
              <Box className='finner_flor'>
                <Typography variant=''>
                  3
                </Typography>
                Floor
              </Box>
              <Box className='finner_flor'>
                <Typography variant=''>
                 4
                </Typography>
                Floor
              </Box>
              <Box className='finner_flor'>
                <Typography variant=''>
                 5
                </Typography>
                Floor
              </Box>
               

            </Box>
        </Box>
      </Modal>
    </div>
  );
}
