import * as React from 'react';
import { Table } from '@mui/material';
import ragnar from "../../Assets/Img/ragnar.jpg";
import grap from "../../Assets/Img/grap.png"; 

function Trandingsecond() {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th> Name</th>
          <th>Floor</th>
          <th>10 Min Floor</th>
          <th>7d Trend</th>
          <th>10 Min Sale</th>
          <th>10 Min Buyers</th>
          <th>10 Min Volume</th>
          <th>Volume</th>
          <th>Listed / Supply </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>2</td>
          <td> <img className='pht_inner' src={ragnar} width="50" alt="crasers images" />   Classee Potion </td>
          <td>--</td>
          <td>--</td>
          <td>----</td>
          <td>--</td>
          <td><img className='' src={grap} width="50" alt="crasers images" /></td>
          <td>--</td>
          <td>1522</td>
          <td>??</td>
        </tr>
        <tr>
          <td>3</td>
          <td> <img className='pht_inner' src={ragnar} width="50" alt="crasers images" />   Classee Potion  </td>
          <td>--</td>
          <td>--</td>
          <td>----</td>
          <td>--</td>
          <td><img className='' src={grap} width="50" alt="crasers images" /></td>
          <td>--</td>
          <td>1522</td>
          <td>??</td>
        </tr>
        <tr>
          <td>4</td>
          <td> <img className='pht_inner' src={ragnar} width="50" alt="crasers images" />   Classee Potion  </td>
          <td>--</td>
          <td>--</td>
          <td>----</td>
          <td>--</td>
          <td><img className='' src={grap} width="50" alt="crasers images" /></td>
          <td>--</td>
          <td>1522</td>
          <td>??</td>
        </tr>
        <tr>
          <td>5</td>
          <td> <img className='pht_inner' src={ragnar} width="50" alt="crasers images" />   Classee Potion  </td>
          <td>--</td>
          <td>--</td>
          <td>----</td>
          <td>--</td>
          <td><img className='' src={grap} width="50" alt="crasers images" /></td>
          <td>--</td>
          <td>1522</td>
          <td>??</td>
        </tr>
        <tr>
          <td>6</td>
          <td> <img className='pht_inner' src={ragnar} width="50" alt="crasers images" />   Classee Potion  </td>
          <td>--</td>
          <td>--</td>
          <td>----</td>
          <td>--</td>
          <td><img className='' src={grap} width="50" alt="crasers images" /></td>
          <td>--</td>
          <td>1522</td>
          <td>??</td>
        </tr>
         
      </tbody>
    </Table>
  );
}

export default Trandingsecond;
 