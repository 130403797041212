import { Box } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';


const Inputbase = styled.input`
    width:100%;
    background: #212035;
    border-radius: 22px;
    border: 0px solid #828282 !important;
    padding: 15px 7px 10px 45px;
    font-size: 18px;
    color: #828282;
    outline:none;
    
`


const Searchsection = () => {
  return (
    <div>
        <Box position='relative'>
            <Box position='absolute' top='0' left='14px' bottom='0' display='flex' alignItems='center'>
                <SearchIcon sx={{ color:'#828282', cursor:'pointer' }}/> 
            </Box>
            <Inputbase type='search' id='search' placeholder='Search table' />
        </Box>
    </div>
  )
}

export default Searchsection; 