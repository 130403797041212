import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MenuHeader from "./components/PageHeader/MenuHeader";
import Landing from "./Pages/LandingPage/Landing";
import Login from "./Pages/LoginFrom/Login";
import SignUp from "./Pages/SignUp/SignUp"; 
import Reset from "./Pages/Reset/Reset";
import Forgot from "./Pages/Forgot/Forgot";
import Update from "./Pages/Update/Update";
import Popular from "./Pages/Popular/Popular"; 
import Collobration from "./Pages/Collobration/collobration";
import User from "./Pages/User/User";
import Browse from "./Pages/Browse/Browse";
import Tranding from "./Pages/Tranding/Tranding";
import Description from "./Pages/Description/Description";
import BottomFooter from "./components/BottomFooter/CopyRightFooter"

const App = () => {
  return (
    <div>
     
      <BrowserRouter basename='/'> 
        <MenuHeader />
        <Routes>
          <Route path="/" element={<Landing />}>
            <Route index element={<Landing />} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} /> 
          <Route path='/Reset' element={<Reset />} /> 
          <Route path='/Forgot' element={<Forgot />} /> 
          <Route path='/Update' element={<Update />} /> 
          <Route path='/popular' element={<Popular />} />
          <Route path='/collobration' element={<Collobration />} /> 
          <Route path='/user' element={<User />} /> 
          <Route path='/browse' element={<Browse />} /> 
          <Route path='/tranding' element={<Tranding />} />
           <Route path='/description' element={<Description/>}/>
        </Routes>  
        <BottomFooter />
      </BrowserRouter>
    </div>
  );
};

export default App;
