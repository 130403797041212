import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import logins from "../../Assets/Login/illustration_login.png";
import LoginFrom from "./SignUpFrom";

const SignUp = () => {
  return (
    <div> 
      <Box
        sx={{
          background: { md: "#fbfbfb", xs: "#000" }, 
          position: "relative",
          display: "flex",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Container maxWidth="xxl">
          <Grid container spacing={2}>
            <Grid item md="3">
              <Box>
                <Box>
                  <Typography
                    variant="h4"
                    color="#212B36"
                    fontWeight="bold"
                    gutterBottom
                    component="h4"
                  >
                    Hi, Welcome to SolSniper
                  </Typography>
                </Box>
                <Box>
                  <img src={logins} width="100%" alt="login images" />
                </Box>
              </Box>
            </Grid>
            <Grid item md="9">
              <Box>
                <LoginFrom />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default SignUp;
